import { useState, useEffect } from 'react';
import './App.css';
import icon from './assets/icon.png'
import { storeBadge } from './assets/store_badge/'

function App() {

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  const appstoreSize = windowSize.width > 450 ? 56 : 28
  const appstorePadding = windowSize.width > 450 ? 12 : 6
  const playstoreSize = windowSize.width > 450 ? 80 : 40

  useEffect(() => {

    const updateWindowSize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', updateWindowSize);

    return () => {
      window.removeEventListener('resize', updateWindowSize);
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img style={{ width: 120, borderRadius: 5 }} src={icon} />
        <div className="App-title" style={{ color: '#000000DE' }}>
          TRADERS GAME
        </div>
        <p className="App-explain" style={{ color: '#000000DE' }}>
          Become the world's richest in the game.
        </p>
        <div style={{ marginTop: 0 }}>
          <a href='https://itunes.apple.com/app/id1572464401'>
            <img style={{ padding: appstorePadding }} height={appstoreSize} src={storeBadge.LIGHT.appstore.en} />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.caminogames.traders'>
            <img height={playstoreSize} src={storeBadge.LIGHT.playstore.en} />
          </a>
        </div>

        <a className="App-try-button-bg" href='https://caminogames.com/traders' style={{ textDecoration: 'none' }}>
          <div className="App-try-button" style={{ height: appstoreSize }}>
            <p className="App-button-text" style={{ verticalAlign: 'middle' }}>
              Try now
            </p>
          </div>
        </a>
      </header>

      <footer>
        <div style={{}}>
          <p >Copyright © CAMINO GAMES. All rights reserved.</p>
          <a className="App-try-button-bg" href='mailto:caminogames@gmail.com' style={{ textDecoration: 'none', color: 'black' }}>
            Contact us
          </a>
        </div>
      </footer>


    </div>
  );
}

export default App;
