import app_store_en_dark from './app_store_en_dark.svg'
import app_store_en_light from './app_store_en_light.svg'
import app_store_ko_dark from './app_store_ko_dark.svg'
import app_store_ko_light from './app_store_ko_light.svg'
import app_store_ja_dark from './app_store_ja_dark.svg'
import app_store_ja_light from './app_store_ja_light.svg'

export const storeBadge = {
    LIGHT: {
        appstore: {
            ko: app_store_ko_light,
            en: app_store_en_light,
            ja: app_store_ja_light
        },
        playstore: {
            ko: 'https://play.google.com/intl/en/badges/images/generic/ko_badge_web_generic.png',
            en: 'https://play.google.com/intl/en/badges/images/generic/en_badge_web_generic.png',
            ja: 'https://play.google.com/intl/en/badges/images/generic/ja_badge_web_generic.png'
        }
    },
    DARK: {
        appstore: {
            ko: app_store_ko_dark,
            en: app_store_en_dark,
            ja: app_store_ja_dark
        },
        playstore: {
            ko: 'https://play.google.com/intl/en/badges/images/generic/ko_badge_web_generic.png',
            en: 'https://play.google.com/intl/en/badges/images/generic/en_badge_web_generic.png',
            ja: 'https://play.google.com/intl/en/badges/images/generic/ja_badge_web_generic.png'
        }
    }
}